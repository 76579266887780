import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import {
  HttpInterceptor,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ApiService } from '../services/api.service';

import { IAppSession, IAppApiResponse } from '../interfaces/interfaces';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  static storage_key: string = 'token';

  private token: string;
  constructor(private storage: Storage, private api: ApiService) {
    this.storage
      .get(AuthInterceptor.storage_key)
      .then((token: string) => {
        this.update(token);
      })
      .catch(() => {
        console.error(
          '[Auth]',
          `Unable to load ${AuthInterceptor.storage_key} from storage`
        );
      });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url: URL = new URL(req.url);

    if (url.pathname.endsWith('/wakeup')) return EMPTY;

    let request: HttpRequest<any> = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`
      }
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        let custom: HttpResponse<any>;

        if (event instanceof HttpResponse) {
          const response: IAppApiResponse = event.body;

          if (
            req.method === 'POST' &&
            (url.pathname.endsWith('/login') ||
              url.pathname.endsWith('/session/refresh'))
          ) {
            this.update(response.data);
            const session: IAppSession = this.getSession();
            custom = event.clone({
              body: {
                status: 1,
                data: session
              }
            });
          } else if (
            req.method === 'POST' &&
            url.pathname.endsWith('/logout')
          ) {
            this.update(undefined);
          }
        }

        return custom || event;
      }),
      catchError(
        (event: any) =>
          new Observable<HttpEvent<any>>(observer => {
            if (event instanceof HttpErrorResponse) {
              const response: IAppApiResponse = event.error;
              if (event.status === 401) {
                this.update(undefined);
              }
            }
            observer.error(event);
            observer.complete();
          })
      )
    );
  }

  private update(token: string, code: string = null) {
    this.token = token;
    if (token) {
      this.storage.set(AuthInterceptor.storage_key, token);
      this.api.setSession(this.getSession());
    } else {
      this.storage.remove(AuthInterceptor.storage_key);
      this.api.setSession(undefined, code);
    }
  }

  private decode(token: string): IAppDecodedToken {
    try {
      const decoded: IAppDecodedToken = JSON.parse(
        window.atob(
          token
            .split('.')[1]
            .replace(/-/g, '+')
            .replace(/_/g, '/')
        )
      );

      return decoded;
    } catch (exception) {
      this.update(undefined, 'corrupted');
    }
  }

  public getSession(): IAppSession {
    const decoded = this.decode(this.token);
    return decoded.data;
  }
}

interface IAppDecodedToken {
  aud: string;
  exp: number;
  data: IAppSession;
}
