import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpInterceptor,
  HttpHandler
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private url: RegExp = /\/config2\/[a-zA-Z0-9\-]+/;
  private maxAge: number = 60000;
  private cache = new Map<string, RequestCacheEntry>();

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isCachable(req)) return next.handle(req);

    const cachedResponse = this.get(req);
    if (req.headers.get('x-refresh')) {
      const results$ = this.sendRequest(req, next);
      return cachedResponse
        ? results$.pipe(startWith(cachedResponse))
        : results$;
    }
    return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
  }

  private sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const noHeaderReq = req.clone({ headers: new HttpHeaders() });

    return next.handle(noHeaderReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.put(req, event);
        }
      })
    );
  }

  private get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) return undefined;

    const isExpired: boolean = cached.lastRead < Date.now() - this.maxAge;
    const expired: string = isExpired ? 'expired ' : '';
    return isExpired ? undefined : cached.response;
  }

  private put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;

    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - this.maxAge;
    this.cache.forEach(entry => {
      if (entry.lastRead < expired) {
        this.cache.delete(entry.url);
      }
    });
  }

  private isCachable(req: HttpRequest<any>): boolean {
    const url: URL = new URL(req.url);
    return req.method === 'GET' && this.url.test(url.pathname);
  }
}

export interface RequestCacheEntry {
  url: string;
  response: HttpResponse<any>;
  lastRead: number;
}

export abstract class RequestCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;
  abstract put(req: HttpRequest<any>, response: HttpResponse<any>): void;
}
