import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { OfflineService } from './offline.service';
// import { FirebaseService } from './firebase.service';

import { IAppApiResponse, IAppSession } from '../interfaces/interfaces';

import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {
  public listen: Observable<IAppSessionStatus>;
  private listener = new Subject<IAppSessionStatus>();
  private session: IAppSession;

  constructor(
    private http: HttpClient,
    private router: Router,
    private offlineService: OfflineService
  ) {
    this.listen = this.listener.asObservable();
    this.reload();
  }

  private reload(): void {}

  static request(
    http: HttpClient,
    method: IAppApiMethod,
    endpoint: string,
    data: any
  ): Promise<IAppApiResponse> {
    return new Promise((resolve, reject) => {
      http[method](endpoint, data).subscribe(
        (response: IAppApiResponse) => {
          if (!environment.production) {
            console.log(`${method}: `, response);
          }
          resolve(response);
        },
        (response: HttpErrorResponse) => {
          if (response.status !== 401 || endpoint.includes('/login'))
            reject(
              Object.assign(response.error, {
                offline: response.status === 0
              })
            );
        }
      );
    });
  }

  public request(
    method: IAppApiMethod,
    endpoint: string,
    data: any
  ): Promise<IAppApiResponse> {
    return new Promise((resolve, reject) => {
      ApiService.request(this.http, method, this.endpoint(endpoint), data)
        .then((response: IAppApiResponse) => {
          resolve(response);
        })
        .catch((response: IAppApiResponse) => {
          if (
            response['offline'] &&
            method === 'post' &&
            /\/clocks/.test(endpoint) &&
            !(data && data._offline)
          ) {
            this.offlineService.push({
              method: method as IAppApiMethod,
              endpoint: endpoint,
              data: data
            });
            reject(response);
          } else reject(response);
        });
    });
  }

  public get(endpoint, data = null): Promise<IAppApiResponse> {
    const options: object = Object.assign(
      {},
      data === null
        ? {}
        : {
            params: new HttpParams().set('data', JSON.stringify(data))
          }
    );

    return this.request('get', endpoint, options);
  }

  public post(endpoint: string, data: any = {}): Promise<IAppApiResponse> {
    return this.request('post', endpoint, data);
  }

  public put(endpoint: string, data: any = {}): Promise<IAppApiResponse> {
    return this.request('put', endpoint, data);
  }

  public delete(endpoint: string, data: any = {}): Promise<IAppApiResponse> {
    return this.request('delete', endpoint, data);
  }

  static endpoint(endpoint: string): string {
    return `${environment.server}${endpoint}`;
  }

  public endpoint(endpoint: string): string {
    return ApiService.endpoint(endpoint);
  }

  public login(credentials: IAppCredentials): Promise<IAppApiResponse> {
    return new Promise((resolve, reject) => {
      this.post('/login', {
        _username: credentials.username,
        _password: credentials.password
      })
        .then((response: IAppApiResponse) => {
          this.setSession(response.data);
          resolve(response);
        })
        .catch((response: IAppApiResponse) => {
          reject(response);
        });
    });
  }

  public loginWithGoogle(id_token: string): Promise<IAppApiResponse> {
    return new Promise((resolve, reject) =>
      this.post('/login/google', {
        id_token: id_token
      })
        .then((response: IAppApiResponse) => {
          if (response.status === 1) {
            this.setSession(response.data);
            resolve(response);
          } else reject(response['code']);
        })
        .catch(error => reject(error))
    );
  }

  public logout(): Promise<IAppApiResponse> {
    return new Promise((resolve, reject) => {
      this.post('/logout')
        .then((response: IAppApiResponse) => {
          this.setSession(undefined);
          resolve(response);
        })
        .catch((response: IAppApiResponse) => {
          reject(response);
        });
    });
  }

  public hasPermission(permission: string): boolean {
    if (
      this.session &&
      this.session.permissions &&
      this.session.permissions.length
    ) {
      for (const node of this.session.permissions)
        if (new RegExp('^' + node.replace('*', '.*') + '$').test(permission))
          return true;
    }
    return false;
  }

  public redirectLogin(): void {
    this.router.navigateByUrl('/login');
  }

  public getSession(): Promise<IAppSession> {
    return new Promise((resolve, reject) => {
      if (this.session) resolve(this.session);
      else {
        const subscription = this.listen.subscribe(
          (sessionStatus: IAppSessionStatus) => {
            if (sessionStatus.status) {
              subscription.unsubscribe();
              resolve(sessionStatus.data);
            }
          }
        );
      }
    });
  }

  public setSession(session: IAppSession, code: string = ''): void {
    this.session = session;
    this.listener.next({
      status: Boolean(session),
      code: code,
      data: session
    });
  }
}

interface IAppDecodedToken {
  aud: string;
  exp: number;
  data: IAppSession;
}

export interface IAppCredentials {
  username: string;
  password: string;
}

export interface IAppSessionStatus {
  status: boolean;
  code: string;
  data?: IAppSession;
}

export type IAppApiMethod = 'get' | 'post' | 'put' | 'delete';
