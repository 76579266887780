import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Platform, Events } from '@ionic/angular';

import { filter } from 'rxjs/operators';

import { Plugins } from '@capacitor/core';
const { SplashScreen, StatusBar } = Plugins;
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { IAppSession, IAppApiResponse } from './interfaces/interfaces';

import { ApiService, IAppSessionStatus } from './services/api.service';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  private session: IAppSession;

  constructor(
    private mobileAccessibility: MobileAccessibility,
    private router: Router,
    private platform: Platform,
    private ionicEvents: Events,
    private translate: TranslateService,
    private api: ApiService
  ) {
    this.initializeApp();
    this.initSessionListener();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.mobileAccessibility)
        this.mobileAccessibility.usePreferredTextZoom(false);
      /*if (this.platform.is('android')) this.statusBar.styleLightContent();
      else if (this.platform.is('ios')) {
        let handleStatusBar = (url: string): void => {
          if (url === '/tabs' || url === '/tabs/home') {
            this.statusBar.styleDefault();
            //this.statusBar.styleLightContent();
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#f2f2f2');
          } else if (url === '/tabs/clocks') {
            this.statusBar.styleLightContent();
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#616161');
          }  else if ('/tabs/profile') {
            this.statusBar.styleDefault();
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#ffffff');
          } else if (url === '/login') {
            this.statusBar.styleDefault();
            this.statusBar.overlaysWebView(true);
          } else {
            this.statusBar.styleDefault();
            this.statusBar.overlaysWebView(true);
          }
        };

        handleStatusBar(this.router.url);

        this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((event: NavigationEnd) => handleStatusBar(event.url));
      } else this.statusBar.styleDefault();*/
      StatusBar.hide().catch(() => {
        console.debug('[StatusBar] Invalid platform');
      });
      SplashScreen.hide().catch(() => {
        console.debug('[SplashScreen] Invalid platform');
      });
    });
  }

  private initSessionListener() {
    this.api.listen.subscribe((sessionStatus: IAppSessionStatus) => {
      if (sessionStatus.status) {
        if (
          sessionStatus.data.permissions.length > 0 &&
          sessionStatus.data.permissions.includes('clocks.license.tablet')
        ) {
          if (this.session) {
            this.session = sessionStatus.data as IAppSession;
            this.translate.use(this.session.lang);
          } else {
            this.session = sessionStatus.data as IAppSession;
            this.initTranslate(this.session);
            this.router.navigateByUrl('/users');
            this.api.post('/session/refresh');
          }
        } else {
          this.api.post('/logout');
        }
      } else {
        this.session = undefined;
        this.router.navigateByUrl('/login');
      }
    });
    this.api.post('/wakeup');
  }

  private initTranslate(session: IAppSession = this.session) {
    this.translate.setDefaultLang('es-ES');

    if (!environment.production) this.translate.use('orig');
    else this.translate.use(session.lang || this.translate.defaultLang);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang !== 'orig') {
        console.log(`[Lang] Changed to ${event.lang}`);
      } else {
        console.log(`[Lang] Setted to ${event.lang}`);
      }
    });
  }
}

Date.prototype.toString = Date.prototype.toISOString;
