import { Injectable } from '@angular/core';
import {
  ToastController,
  AlertController,
  LoadingController
} from '@ionic/angular';

import { ToastOptions, AlertOptions, LoadingOptions } from '@ionic/core';

@Injectable()
export class NotificationService {
  constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController
  ) {}

  async toast(options: ToastOptions): Promise<HTMLIonToastElement> {
    let finalOptions: ToastOptions = Object.assign(options, {
      duration: 2000,
      cssClass: 'center-toast'
    });
    const toast = await this.toastCtrl.create(finalOptions);
    toast.present();
    return toast;
  }

  async alert(options: AlertOptions): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create(options);
    alert.present();
    return alert;
  }

  async loading(options: LoadingOptions): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingCtrl.create(options);
    loading.present();
    return loading;
  }
}
