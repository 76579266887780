import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { Storage } from '@ionic/storage';
import { Plugins } from '@capacitor/core';
const { Network } = Plugins;

import { ApiService, IAppApiMethod } from './api.service';

import { IAppApiResponse } from '../interfaces/interfaces';

import { environment } from '../../environments/environment';

@Injectable()
export class OfflineService {
  static requests = 'requests';

  public requests: Array<IAppOfflineRequest>;
  private connected: boolean = true;
  constructor(private storage: Storage, private http: HttpClient) {
    this.init();
  }

  public init(): void {
    try {
      /*let handler = Network.addListener('networkStatusChange', status => {
        this.connected = status.connected;
        if (this.connected) this.process();
      });*/
    } catch (e) {
      console.debug('[Network] Unable to listen', e);
    }

    this.storage
      .get(OfflineService.requests)
      .then((requests: Array<IAppOfflineRequest>) => {
        this.requests = requests || [];
      })
      .catch(() => {
        console.error('ERROR on @ionic/storage catch');
      });
  }

  public push(request: IAppOfflineRequest) {
    this.requests.push(request);
    this.set(this.requests);
  }

  public set(data: Array<IAppOfflineRequest>): void {
    this.requests = data;
    this.storage.set(OfflineService.requests, data);
  }

  public list(): Promise<Array<IAppOfflineRequest>> {
    return this.storage.get(OfflineService.requests);
  }

  public process(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.list().then(async (requests: Array<IAppOfflineRequest>) => {
        if (!requests) return;
        for (var index = 0; index < requests.length; index++) {
          if (!this.connected) break;
          try {
            if (!requests[index].sent) {
              var response = await this.send(requests[index]);
              requests[index].sent = true;
            }
          } catch (e) {
            console.error('Error sending offline', e);
          }
        }
        this.set(
          requests.filter((request: IAppOfflineRequest) => !request.sent)
        );
        if (this.requests.length === 0) resolve();
      });
    });
  }

  public getRequests(): Observable<Array<IAppOfflineRequest>> {
    return of(this.requests);
  }

  private send(request: IAppOfflineRequest): Promise<IAppApiResponse> {
    return ApiService.request(
      this.http,
      request.method.toLowerCase() as IAppApiMethod,
      ApiService.endpoint(request.endpoint),
      Object.assign({}, request.data, {
        _offline: true
      })
    );
  }
}

export interface IAppOfflineRequest {
  method: IAppApiMethod;
  endpoint: string;
  data: any;
  sent?: boolean;
}
