//------------------------------------------------------------------------------
//  Angular
//------------------------------------------------------------------------------

import { NgModule, LOCALE_ID } from '@angular/core';
import {
  registerLocaleData,
  HashLocationStrategy,
  LocationStrategy,
  APP_BASE_HREF
} from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import es from '@angular/common/locales/es';

registerLocaleData(es);

//------------------------------------------------------------------------------
//  Ionic
//------------------------------------------------------------------------------

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

//------------------------------------------------------------------------------
//  Ionic Native
//------------------------------------------------------------------------------

import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

//------------------------------------------------------------------------------
//  Components
//------------------------------------------------------------------------------

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//------------------------------------------------------------------------------
//  Services
//------------------------------------------------------------------------------

import { ApiService } from './services/api.service';
import { NotificationService } from './services/notification.service';
import { OfflineService } from './services/offline.service';

//------------------------------------------------------------------------------
//  Interceptors
//------------------------------------------------------------------------------

import { CachingInterceptor } from './interceptors/cache.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
//import { OfflineInterceptor } from './interceptors/offline.interceptor';

//------------------------------------------------------------------------------
//  Modules
//------------------------------------------------------------------------------

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${location.origin}/assets/i18n/`,
    '.json'
  );
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  providers: [
    ApiService,
    NotificationService,
    OfflineService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true
    },*/
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    },

    MobileAccessibility
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
